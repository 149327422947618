import React, { Component, useEffect, useState } from 'react'
import styled from 'styled-components'
import Button from '../Button'
import Snackbar from '@mui/material/Snackbar'
import Icon from '../Icon'
import IconButton from '@mui/material/IconButton'
import { withStyles } from '@mui/styles'
import ItemArticle from '../../topics/Article/components/ItemArticle'
import Text from '../Text'
import translate from '../../modules/translate'
import { withRouter } from 'react-router-dom'
import { setStoredItem, getStoredItem, clearStoredItem } from '../../modules/stored-item'
import moment from 'moment'
import isServerSide from '../../modules/is-server-side'
import { useQuery } from '@tanstack/react-query'
import routes from '../../topics/routes'
import options from '../../config/options'

const Wrapper = styled.div`
  @media (max-width: 600px) {
    .MuiSnackbar-anchorOriginBottomRight {
      bottom: 0px;
      left: 0px;
      right: 0px;
    }
  }
`

const StyledSnackbar = styled(Snackbar)`
  max-width: 50%;
  min-width: 50%;
  @media (max-width: 1340px) {
    max-width: 63%;
    min-width: 63%;
  }
  @media (max-width: 1080px) {
    min-width: calc(100% - 48px);
  }
  @media (max-width: 600px) {
    min-width: 100%;
    max-width: unset;
  }

  > div {
    background-color: #fff;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  > div > div {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  @media (max-width: 450px) {
    > div {
      padding-right: 0;
      padding-left: 0;
      padding-top: 0;
      padding-bottom: 0;
    }

    > div > div {
      padding-top: 10px;
      padding-bottom: 5px;
    }
  }
`

const StyledIcon = styled(Icon)`
  color: #000;
  cursor: pointer;
`

const StyledButton = styled(Button)`
  margin-left: 10px;
  color: grey;
  border-color: grey;
  @media (max-width: 450px) {
    margin: 0;
    width: 200px;
  }
`

const ToTheHomePageButton = styled(Button)`
  @media (max-width: 450px) {
    width: 200px;
  }
`

const IconButtonWrapper = styled.div`
  position: absolute;
  right: 0;
  z-index: 999;
  margin-top: -12px;
  margin-right: -12px;
  @media (max-width: 500px) {
    display: none;
  }
`

const IconButtonWrapperBottom = styled.div`
  width: 100%;
  z-index: 999;
  display: none;
  @media (max-width: 500px) {
    display: block;
  }
`

const StyledIconButton = styled(IconButton)`
  position: absolute;
  right: 0;
`

const WrapperMessage = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`

const ButtonWrapper = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  @media (max-width: 450px) {
    flex-direction: column;
    height: 130px;
    justify-content: space-around;
  }
`

const StyledText = styled(Text)`
  text-align: center;
  font-size: ${(props) => props.theme.fontSize.title3};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.title3};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.title3};
  }
  margin-bottom: 10px;
  margin-top: 10px;
  padding-right: 10px;
  padding-left: 10px;
  @media (max-width: 450px) {
    font-size: ${(props) => props.theme.mobile.fontSize.text};
  }
`

const StyledItemArticle = styled(ItemArticle)`
  flex-wrap: nowrap;
  cursor: pointer;
`

const styles = () => ({
  message: {
    width: '100%',
  },
})

const storeLastArticle = (article) => {
  setStoredItem('lastArticleId', article.id)
  setStoredItem('lastArticleDate', article.date.toString())
}

const NewNewsMessage = ({ open = false, location, history, ...props }) => {
  const isHomePage = location.pathname !== '/'
  const shouldHide = getStoredItem('hide_new_news_message') === 'yes'
  const isOpen = open && !shouldHide && isHomePage

  const [openState, setOpenState] = useState(!!isOpen)
  const [currentArticle, setCurrentArticle] = useState(undefined)

  const { isLoading, error, data, isFetching, isRefetching } = useQuery(['newsticker'], {
    refetchInterval: !isServerSide() ? options.NewNewsMessage.checkInterval : false,
  })

  let locationPath = undefined
  if (!isServerSide()) {
    locationPath = location ? location.pathname : window.location.pathname
  }
  if (locationPath && locationPath === '/') {
    return false
  }

  const handleBackToStart = () => {
    history.push(routes.getOptions('home').path)
  }
  const handleClose = () => {
    setOpenState(false)
  }
  const handleHiding = () => {
    setStoredItem('hide_new_news_message', 'yes')
    setOpenState(false)
  }

  if (error) {
    return false
  }
  if ((isLoading || isFetching || isRefetching) && !data) {
    return false
  }
  const lastArticle = data.data.articles[0]

  let clientLastArticleId =
    !isNaN(parseFloat(getStoredItem('lastArticleId'))) && parseFloat(getStoredItem('lastArticleId')) !== undefined
      ? parseFloat(getStoredItem('lastArticleId'))
      : 0

  if (!clientLastArticleId && lastArticle) {
    storeLastArticle(lastArticle)
  }

  if (lastArticle && lastArticle.home) {
    let lastArticleDate = moment(getStoredItem('lastArticleDate')).format()
    let currentArticleDate = moment(lastArticle.date).format()

    if (
      clientLastArticleId !== lastArticle.id &&
      locationPath !== lastArticle.url &&
      moment(currentArticleDate).isAfter(lastArticleDate)
    ) {
      storeLastArticle(lastArticle)
      if (openState === false && !shouldHide) {
        setCurrentArticle(lastArticle)
        setOpenState(true)
      }
    }
  }

  const trackLabel = currentArticle && `${currentArticle.title} | ${currentArticle.url}`
  return (
    <Wrapper {...props}>
      <StyledSnackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={openState}
        onClose={handleClose}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={
          <WrapperMessage>
            <IconButtonWrapper>
              <StyledIconButton onClick={handleClose}>
                <StyledIcon icon="close" />
              </StyledIconButton>
            </IconButtonWrapper>
            <StyledItemArticle
              track={{ category: 'New News Message', label: trackLabel }}
              onClick={() => {
                if (!isServerSide() && currentArticle) {
                  window.location.href = currentArticle.url
                }
                handleClose()
              }}
              view="small_landscape"
              article={currentArticle}
            />
            <StyledText>
              {translate('there_are')}
              <strong> {translate('new_news')} </strong>
              {translate('on_the_site')}
            </StyledText>
            <ButtonWrapper>
              <ToTheHomePageButton onClick={handleBackToStart}>{translate('to_the_homepage')}</ToTheHomePageButton>
              <StyledButton onClick={handleHiding}>{translate('do_not_show_again')}</StyledButton>
              <IconButtonWrapperBottom>
                <StyledIconButton onClick={handleClose}>
                  <StyledIcon icon="close" />
                </StyledIconButton>
              </IconButtonWrapperBottom>
            </ButtonWrapper>
          </WrapperMessage>
        }
      />
    </Wrapper>
  )
}

export default withRouter(withStyles(styles)(NewNewsMessage))
